import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { googleProvider } from "../config/firebase";
import { createCallableFunction } from '../config/firebase';
import { ClipLoader } from 'react-spinners';
import { useUser } from '../components/utils/UserContext';

import oniKanjiLoginImage from '../images/onikanji-login-image 1.png';
import OniKanjiLogo from '../images/landingPageAssets/onikanji-logo-landing-page.png';

const updateLastLoginDate = createCallableFunction('updateLastLoginDate');

const OnikanjiLogin = () => {
  const { user, loading: userLoading } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "OniKanji - Login";
    if (!userLoading && user) {
      navigate('/home');
    }
  }, [user, userLoading, navigate]);

  if (userLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-mainBackgroundColor">
        <ClipLoader size={50} color="#FF6600" />
      </div>
    );
  }

  if (user) {
    return null; // This will prevent any flash of content before redirecting
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError("");
    setIsLoading(true);

    if (email.trim() === "" || password.trim() === "") {
      setLoginError("Email and password are required.");
      setIsLoading(false);
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateLastLoginDate(user.uid);
      navigate('/home');
    } catch (error) {
      console.error("Error signing in:", error.code, error.message);
      if (error.code === 'auth/email-already-in-use') {
        setLoginError("Email is already in use.");
      } else {
        setLoginError("Invalid email or password. Please try again.");
      }
      setIsLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    setIsLoading(true);
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User signed in with Google successfully");
      await updateLastLoginDate(result.user.uid);
      navigate('/home');
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setLoginError("Failed to sign in with Google. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setResetMessage("Error sending reset email. Please try again.");
    }
  };

  return (
    <div className='min-h-screen flex justify-center items-center bg-mainBackgroundColor p-4'>
      <div className='bg-white rounded-lg shadow-blueBoxShadow flex flex-col lg:flex-row w-full max-w-6xl'>
        <div className='w-full lg:w-1/2 h-64 lg:h-auto flex-shrink-0 bg-gray-200'>
          <img 
            src={oniKanjiLoginImage} 
            alt="Onikanji Login" 
            className='w-full h-full object-cover lg:object-cover rounded-t-lg lg:rounded-l-lg lg:rounded-tr-none' 
          />
        </div>
        <div className='w-full lg:w-1/2 p-8 flex flex-col justify-center'>
          <img 
            src={OniKanjiLogo} 
            alt="Onikanji Logo" 
            className='mb-6 mx-auto h-12 cursor-pointer' 
            onClick={() => window.location.href = '/'} 
          />
          <h2 className='text-2xl font-bold mb-6 text-center text-logoColor'>Login to Onikanji</h2>
          <form className='space-y-4' onSubmit={handleLogin}>
            <div>
              <label htmlFor="email" className='block text-sm font-medium text-gray-700'>Email address</label>
              <input 
                type="email" 
                id="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor' 
                autoComplete="username"
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className='block text-sm font-medium text-gray-700'>Password</label>
              <input 
                type={showPassword ? "text" : "password"} 
                id="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor pr-10'
                autoComplete="current-password"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-6"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5 text-gray-500" />
                ) : (
                  <FaEye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            {loginError && (
              <div className="mt-1 text-sm text-red-600">
                {loginError}
              </div>
            )}
            <button 
              type="submit" 
              className='w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor'
              disabled={isLoading} 
            >
              {isLoading ? (
                <>
                  <ClipLoader size={16} color="#ffffff" className="mr-2" />
                  Signing in...
                </>
              ) : (
                'Sign in'
              )}
            </button>
          </form>
          <div className='mt-6'>
            <div className='relative'>
              <div className='absolute inset-0 flex items-center'>
                <div className='w-full border-t border-gray-300'></div>
              </div>
              <div className='relative flex justify-center text-sm'>
                <span className='px-2 bg-white text-gray-500'>Or continue with</span>
              </div>
            </div>
            <div className='mt-6'>
              <button 
                onClick={signInWithGoogle}
                disabled={isLoading}
                className='w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor'
              >
                {isLoading ? 'Signing in...' : 'Sign in with Google'}
              </button>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row items-center justify-between'>
            <div className='text-sm mb-2 sm:mb-0'>
              <button 
                onClick={() => setIsForgotPasswordOpen(true)}
                className='font-medium text-logoColor hover:text-darkOrangeColor'
              >
                Forgot your password?
              </button>
            </div>
            <div className='text-sm'>
              <a href="/signup" className='font-medium text-logoColor hover:text-darkOrangeColor'>
                Don't have an account? Sign up
              </a>
            </div>
          </div>
        </div>
      </div>
      {isForgotPasswordOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Reset Password</h2>
            <form onSubmit={handleForgotPassword}>
              <div>
                <label htmlFor="resetEmail" className='block text-sm font-medium text-gray-700'>Email Address</label>
                <input
                  type="email"
                  id="resetEmail"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                  className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor'
                />
              </div>
              <button type="submit" className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor mt-4'>
                Send Reset Email
              </button>
            </form>
            {resetMessage && (
              <div className="mt-4 p-2 bg-blue-100 text-blue-700 rounded">
                {resetMessage}
              </div>
            )}
            <button onClick={() => setIsForgotPasswordOpen(false)} className="mt-4 text-sm text-gray-600 hover:text-gray-800">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnikanjiLogin;
