// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { UserProvider } from './components/utils/UserContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import your pages
import OniKanjiLandingPage from './pages/onikanjiLandingPage';
import OniKanjiLogin from './pages/onikanjiLogin';
import OniKanjiSignUp from './pages/onikanjiSignUp';
import KanjiHomePage from './pages/kanjiHomePage';
import AllKanji from './pages/allKanji';
import KanjiLevels from './pages/kanjiLevels';
import KanjiDetail from './pages/kanjiDetails';
import KanjiNewLesson from './pages/kanjiNewLesson';
import KanjiLessonQuiz from './pages/kanjiLessonQuiz';
import KanjiReviewPage from './pages/kanjiReviews';
import KanjiLevelUpPage from './pages/kanjiLevelUpPage';
import TotalStats from './pages/kanjiTotalStats';
import MyProfilePage from './pages/kanjiMyProfilePage';
import OniKanjiPricing from './pages/onikanjiPricing';
import OnikanjiError from './pages/onikanjiError';

// Firebase Analytics Imports
import { analytics } from './config/firebase';
import { logEvent } from "firebase/analytics";

const queryClient = new QueryClient();

// Create a component to wrap Routes with page tracking
const AppRoutes = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Log a page_view event with Firebase Analytics
    logEvent(analytics, 'page_view', {
      page_path: location.pathname + location.search,
      page_title: document.title,
    });
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<OniKanjiLandingPage />} />
      <Route path='/pricing' element={<OniKanjiPricing />} />
      <Route path='/login' element={<OniKanjiLogin />} />
      <Route path='/signup' element={<OniKanjiSignUp />} />
      <Route path="/home" element={<KanjiHomePage />} />
      <Route path='/kanji' element={<AllKanji />} />
      <Route path='/levels/:level' element={<KanjiLevels />} />
      <Route path='/kanji/:kanji' element={<KanjiDetail />} />
      <Route path='/new-lesson/' element={<KanjiNewLesson />} />
      <Route path='/quiz/' element={<KanjiLessonQuiz />} />
      <Route path='/reviews/' element={<KanjiReviewPage />} />
      <Route path='/level-up' element={<KanjiLevelUpPage />} />
      <Route path='/my-stats' element={<TotalStats />} />
      <Route path='/my-profile' element={<MyProfilePage />} />
      <Route path='*' element={<OnikanjiError />} />
    </Routes>
  );
};

function App() {
  React.useEffect(() => {
    // Optional: Log app initialization
    logEvent(analytics, 'app_initialized');
  }, []);

  return (
    <div className='App'>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AppRoutes /> {/* Page tracking is handled within AppRoutes */}
          </Router>
        </QueryClientProvider>
      </UserProvider>
    </div>
  );
}

export default App;
