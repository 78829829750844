import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

export const fetchAllLeaderboards = async (itemLimit = 5) => {
  // Ensure itemLimit doesn't exceed 10
  const validatedLimit = Math.min(itemLimit, 10);
  
  const db = getFirestore();
  const leaderboards = {
    hallOfShame: [],
    speedRun: [],
    userStats: []
  };

  const fetchCollectionData = async (collectionName, fields) => {
    const leaderboardCollection = collection(db, collectionName);
    const leaderboardQuery = query(
      leaderboardCollection,
      orderBy('position', 'asc'),
      limit(validatedLimit)
    );
    const leaderboardSnap = await getDocs(leaderboardQuery);
    return leaderboardSnap.docs.map(doc => {
      const data = doc.data();
      return fields.reduce((acc, field) => {
        acc[field] = data[field];
        return acc;
      }, {});
    });
  };

  try {
    const [hallOfShameData, speedRunData, userStatsData] = await Promise.all([
      fetchCollectionData('leaderboardHallOfShame', ['currentLevel', 'lastUpdated', 'position', 'publicUsername', 'totalAccuracyValue']),
      fetchCollectionData('leaderboardSpeedRun', ['currentLevel', 'lastUpdated', 'position', 'publicUsername', 'totalReviewMinutes']),
      fetchCollectionData('leaderboardUserStats', ['currentLevel', 'lastUpdated', 'position', 'publicUsername', 'totalAccuracyValue'])
    ]);

    leaderboards.hallOfShame = hallOfShameData;
    leaderboards.speedRun = speedRunData;
    leaderboards.userStats = userStatsData;
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
    return { error: 'Failed to fetch leaderboard data' };
  }

  return leaderboards;
};
