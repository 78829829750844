import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import levelUpImage from '../images/appHomeAssets/onikanji-level-up.png';

const KanjiLevelUpPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const levelStats = location.state?.levelStats;

    useEffect(() => {
        if (!userLoading) {
            if (!user) {
                navigate('/login');
            } else if (!levelStats) {
                navigate('/home');
            }
        }
    }, [user, userLoading, navigate, levelStats]);

    if (userLoading || !levelStats) {
        return <div>Loading...</div>; 
    }

    const formatLevelDuration = (duration) => {
        if (!duration) return 'N/A';
        const { days, hours, minutes } = duration;
        return `${days} day${days !== 1 ? 's' : ''}, ${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    };

    const formatNumber = (num, decimalPlaces = 2) => {
        return Number.isFinite(num) ? num.toFixed(decimalPlaces) : 'N/A';
    };

    const handleContinueToDashboard = () => {
        navigate('/home', { state: { previousPath: '/level-up' } });
    };

    return (
        <div className='bg-gradient-to-br from-indigo-400 via-purple-500 to-pink-500 font-noto-sans-jp min-h-screen'>
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="container mx-auto px-4 py-8">
                <div className="bg-[#f8f8f8] shadow-lg rounded-lg p-4 sm:p-8 mt-8 transform hover:scale-105 transition duration-300">
                    <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6 text-indigo-600">おめでとうございます! レベルアップしました!</h1>
                    <p className="text-xl sm:text-2xl text-center mb-4 sm:mb-8 text-gray-700">Even dust, when piled up, can become a mountain</p>
                    <p className="text-xl sm:text-2xl text-center mb-4 sm:mb-8 text-gray-700">塵も積もれば山となる</p>

                    <div className="flex flex-col md:flex-row gap-6">
                        <div className="bg-indigo-100 p-6 rounded-md shadow-md transform hover:rotate-1 transition duration-300 md:w-1/2">
                            <h2 className="text-2xl font-semibold mb-4 text-indigo-800">Level Stats</h2>
                            <p className="text-lg">This Level Took You: <span className="font-bold text-indigo-600">{formatLevelDuration(levelStats.levelDuration)}</span></p>
                            <p className="text-lg">Average Time To Answer Per Kanji: <span className="font-bold text-indigo-600">{formatNumber(levelStats.averageTimePerKanji)} Seconds</span></p>
                            <p className="text-lg">Vocab Words Learned: <span className="font-bold text-indigo-600">Coming Soon</span></p>
                            <p className="text-lg">Mistakes Made: <span className="font-bold text-indigo-600">{levelStats.mistakesMade || 0}</span></p>
                        </div>
                        <div className="bg-indigo-100 p-6 rounded-md shadow-md transform hover:rotate-1 transition duration-300 md:w-1/2">
                            <h2 className="text-2xl font-semibold mb-4 text-indigo-800">Level Highlights</h2>
                            <p className="text-lg">Most Challenging Kanji: <span className="font-bold text-indigo-600">{levelStats.mostChallengingKanji || 'N/A'}</span></p>
                            <p className="text-lg">Easiest Kanji: <span className="font-bold text-indigo-600">{levelStats.easiestKanji || 'N/A'}</span></p>
                            <p className="text-lg">Total Reviews: <span className="font-bold text-indigo-600">{levelStats.totalReviews || 0}</span></p>
                            <p className="text-lg">Accuracy Rate: <span className="font-bold text-indigo-600">{formatNumber(levelStats.accuracyRate)}%</span></p>
                        </div>
                    </div>

                    {/* Responsive image */}
                    <div className="flex justify-center mt-8 mb-8">
                        <img src={levelUpImage} alt="Level Up" className="w-4/5 sm:w-3/5 md:w-2/5 object-contain" />
                    </div>

                    <div className="mt-8 sm:mt-12 text-center">
                        <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-indigo-800">Continue The Fight</h2>
                        <p className="text-lg sm:text-xl mb-4 sm:mb-6 text-gray-700">You've unlocked new kanji and vocabulary for the next level!</p>
                        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                            <button
                                className="w-full sm:w-auto bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-full transform hover:scale-110 transition duration-300"
                                onClick={handleContinueToDashboard}
                            >
                                Continue to Dashboard
                            </button>
                            <button
                                className="w-full sm:w-auto bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-full transform hover:scale-110 transition duration-300"
                            >
                                Share Achievement
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KanjiLevelUpPage;
