import React from 'react';

export function renderSentenceWithFurigana(
  sentence,
  furiganaSampleSentence,
  vocabWord,
  excludeFurigana = false
) {
  if (!sentence) {
    return sentence;
  }

  // Remove explanation in parentheses from the end of the sentence
  sentence = sentence.replace(/\s*\(.*?\)\s*$/, '');

  let furiganaData;

  // Check if the sentence uses the // format
  if (sentence.includes('//')) {
    furiganaData = [];
    const parts = sentence.split('//');
    let plainSentence = '';

    // Start from the first part (which is text) and process pairs
    plainSentence += parts[0]; // Add the first text segment

    // Process pairs of furigana and text
    for (let i = 1; i < parts.length; i += 2) {
      const furigana = parts[i];
      // Get the preceding text segment to find the kanji
      const prevText = parts[i - 1];

      // Find all kanji characters in the preceding text
      const kanjiMatch = prevText.match(/[\u4E00-\u9FAF]+/g);
      const kanji = kanjiMatch ? kanjiMatch[kanjiMatch.length - 1] : prevText.slice(-1);

      // Create a furigana pair for the entire kanji compound
      const pair = {};
      pair[kanji] = furigana;
      furiganaData.push(pair);

      // Add the next text segment if it exists
      if (parts[i + 1]) {
        plainSentence += parts[i + 1];
      }
    }

    // Update the sentence to the plain version without furigana markers
    sentence = plainSentence;
  } else if (furiganaSampleSentence) {
    // Original JSON format handling
    try {
      furiganaData = JSON.parse(furiganaSampleSentence.replace(/'/g, '"'));
    } catch (error) {
      console.error('Error parsing furigana data:', error);
      return sentence;
    }
  } else {
    return sentence;
  }

  // Function to extract kanji from a string
  function extractKanji(str) {
    return str.replace(/[^\u4E00-\u9FAF]/g, '');
  }

  // Extract kanji from the vocabWord
  const vocabKanji = extractKanji(vocabWord);

  // Build tokens array
  let tokens = [];
  let sentenceIndex = 0;
  furiganaData.forEach((item) => {
    const kanji = Object.keys(item)[0];
    const reading = item[kanji];

    // Find the position of kanji in sentence starting from sentenceIndex
    const position = sentence.indexOf(kanji, sentenceIndex);
    if (position === -1) {
      // Kanji not found, this should not happen
      console.error(
        `Kanji ${kanji} not found in sentence starting from index ${sentenceIndex}`
      );
      return;
    }

    // Add tokens for any characters before the kanji
    if (position > sentenceIndex) {
      const textBeforeKanji = sentence.substring(sentenceIndex, position);
      for (const char of textBeforeKanji) {
        tokens.push({
          text: char,
          furigana: null,
          isKanji: false,
          index: sentenceIndex,
        });
        sentenceIndex += 1;
      }
    }

    // Check if kanji contains the vocabWord's kanji
    const indexInKanji = kanji.indexOf(vocabKanji);

    if (indexInKanji !== -1) {
      // The vocab kanji is part of this kanji token
      // Split the kanji and reading accordingly

      const beforeVocabKanji = kanji.slice(0, indexInKanji);
      const vocabKanjiPart = kanji.slice(indexInKanji, indexInKanji + vocabKanji.length);
      const afterVocabKanji = kanji.slice(indexInKanji + vocabKanji.length);

      // Now, split the reading accordingly
      const kanjiParts = [beforeVocabKanji, vocabKanjiPart, afterVocabKanji].filter(
        (part) => part !== ''
      );

      const totalKanjiLength = kanji.length;
      const totalReadingLength = reading.length;

      // Calculate reading lengths proportionally
      const kanjiPartLengths = kanjiParts.map((part) => part.length);
      const totalKanjiChars = kanjiPartLengths.reduce((sum, len) => sum + len, 0);

      let readingLengths = kanjiPartLengths.map((len) =>
        Math.round((len / totalKanjiChars) * totalReadingLength)
      );

      // Adjust readingLengths to sum up to totalReadingLength
      let adjustedReadingLength = readingLengths.reduce((sum, len) => sum + len, 0);
      while (adjustedReadingLength < totalReadingLength) {
        readingLengths[readingLengths.length - 1] += 1;
        adjustedReadingLength += 1;
      }
      while (adjustedReadingLength > totalReadingLength) {
        readingLengths[readingLengths.length - 1] -= 1;
        adjustedReadingLength -= 1;
      }

      // Split the reading into parts
      const readingParts = [];
      let readingIndex = 0;
      for (let i = 0; i < readingLengths.length; i++) {
        const len = readingLengths[i];
        const part = reading.substr(readingIndex, len);
        readingParts.push(part);
        readingIndex += len;
      }

      // Now, create tokens for each part
      for (let i = 0; i < kanjiParts.length; i++) {
        const part = kanjiParts[i];
        const readingPart = readingParts[i];
        const isVocabWord = part === vocabKanji;

        tokens.push({
          text: part,
          furigana: readingPart,
          isKanji: true,
          isVocabWord: isVocabWord,
          index: sentenceIndex,
        });

        sentenceIndex += part.length;
      }
    } else {
      // The vocab kanji is not part of this kanji token

      // Add token for the kanji
      tokens.push({
        text: kanji,
        furigana: reading,
        isKanji: true,
        index: position,
      });

      sentenceIndex = position + kanji.length;
    }
  });

  // Add tokens for any remaining characters after the last kanji
  if (sentenceIndex < sentence.length) {
    const remainingText = sentence.substring(sentenceIndex);
    for (const char of remainingText) {
      tokens.push({
        text: char,
        furigana: null,
        isKanji: false,
        index: sentenceIndex,
      });
      sentenceIndex += 1;
    }
  }

  // Mark tokens that together form the vocabKanji
  markVocabWordInTokens(tokens, vocabKanji);

  // Now render the tokens
  const elements = tokens.map((token, idx) => {
    if (token.isKanji) {
      const isVocabWord = token.isVocabWord;
      const highlightClass = isVocabWord ? 'underline underline-offset-8' : '';
      return (
        <ruby key={idx}>
          <rb className={highlightClass}>{token.text}</rb>
          <rt>{(!isVocabWord || !excludeFurigana) ? token.furigana : ''}</rt>
        </ruby>
      );
    } else {
      return <span key={idx}>{token.text}</span>;
    }
  });

  return elements;
}

// New function to mark tokens that together form the vocabKanji
function markVocabWordInTokens(tokens, vocabKanji) {
  for (let i = 0; i < tokens.length; i++) {
    let combinedText = '';
    for (let j = i; j < tokens.length; j++) {
      if (!tokens[j].isKanji) {
        break; // We only consider consecutive kanji tokens
      }
      combinedText += tokens[j].text;
      if (combinedText === vocabKanji) {
        // Mark tokens from i to j inclusive
        for (let k = i; k <= j; k++) {
          tokens[k].isVocabWord = true;
        }
        return; // Exit after marking the vocab word
      }
      // If combinedText length exceeds vocabKanji length, no need to continue
      if (combinedText.length > vocabKanji.length) {
        break;
      }
    }
  }
}
