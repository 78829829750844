// UserContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { getFirestore, doc, collection, query, where, onSnapshot } from 'firebase/firestore';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(null);
  const [publicUsername, setPublicUsername] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionName, setSubscriptionName] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionCancelAtPeriodEnd, setSubscriptionCancelAtPeriodEnd] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const db = getFirestore();
    let unsubscribeSubscription = null;
    let unsubscribeUserDoc = null;

    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => {
      setLoading(true);
      setError(null);

      // Unsubscribe from previous user doc and subscription when auth state changes
      if (unsubscribeUserDoc) {
        unsubscribeUserDoc();
        unsubscribeUserDoc = null;
      }
      if (unsubscribeSubscription) {
        unsubscribeSubscription();
        unsubscribeSubscription = null;
      }

      if (firebaseUser) {
        const userDocRef = doc(db, 'UserCollection', firebaseUser.uid);

        unsubscribeUserDoc = onSnapshot(
          userDocRef,
          (userDoc) => {
            if (userDoc.exists()) {
              const userData = {
                uid: firebaseUser.uid,
                emailVerified: firebaseUser.emailVerified,
                providerData: firebaseUser.providerData,
                ...userDoc.data(),
              };
              setUser(userData);
              setUsername(userData.userName);
              setPublicUsername(userData.publicUsername);

              // Always set up a new subscription listener for the current user
              const subscriptionsRef = collection(
                db,
                'UserCollection',
                firebaseUser.uid,
                'subscriptions'
              );
              const q = query(
                subscriptionsRef,
                where('status', 'in', ['trialing', 'active'])
              );
              unsubscribeSubscription = onSnapshot(
                q,
                (snapshot) => {
                  if (!snapshot.empty) {
                    const subscriptionDoc = snapshot.docs[0];
                    const subscriptionData = subscriptionDoc.data();

                    if (
                      typeof subscriptionData.status === 'string' &&
                      Array.isArray(subscriptionData.items) &&
                      subscriptionData.current_period_end
                    ) {
                      setSubscriptionStatus(subscriptionData.status);
                      setSubscriptionEndDate(subscriptionData.current_period_end.toDate().toLocaleDateString());
                      setSubscriptionCancelAtPeriodEnd(subscriptionData.cancel_at_period_end);

                      const subscriptionName = subscriptionData.items[0]?.price?.product?.name || 'Unknown Subscription';
                      setSubscriptionName(subscriptionName);
                    } else {
                      console.error('Invalid subscription data format:', subscriptionData);
                      throw new Error('Invalid subscription data format');
                    }
                  } else {
                    resetSubscriptionData();
                  }
                },
                (error) => {
                  console.error('Error fetching subscription data:', error);
                  setError('Failed to fetch subscription data');
                  resetSubscriptionData();
                }
              );
            } else {
              resetUserData();
            }
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching user data:', error);
            setError('Failed to fetch user data');
            resetUserData();
            setLoading(false);
          }
        );
      } else {
        resetUserData();
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeUserDoc) {
        unsubscribeUserDoc();
        unsubscribeUserDoc = null;
      }
      if (unsubscribeSubscription) {
        unsubscribeSubscription();
        unsubscribeSubscription = null;
      }
    };
  }, []);

  const resetUserData = () => {
    setUser(null);
    setUsername(null);
    setPublicUsername(null);
    resetSubscriptionData();
  };

  const resetSubscriptionData = () => {
    setSubscriptionStatus(null);
    setSubscriptionName(null);
    setSubscriptionEndDate(null);
    setSubscriptionCancelAtPeriodEnd(null);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        username,
        publicUsername,
        loading,
        subscriptionStatus,
        subscriptionName,
        subscriptionEndDate,
        subscriptionCancelAtPeriodEnd,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
