import React, { useEffect, useState } from 'react';
import { useUser } from '../components/utils/UserContext';
import Navbar from '../components/navBar';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase'; 
import { ClipLoader } from 'react-spinners';

function LevelPage() {
    const { level } = useParams();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();

    const [totalLevels, setTotalLevels] = useState(0);
    const [isLoadingLevels, setIsLoadingLevels] = useState(true);

    useEffect(() => {
        document.title = `OniKanji - Level ${level}`;
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [user, userLoading, navigate, level]);

    // Fetch total number of levels
    useEffect(() => {
        const fetchTotalLevels = async () => {
            setIsLoadingLevels(true);
            try {
                const levelsSnapshot = await getDocs(query(collection(db, "allKanji")));
                const levelsSet = new Set();
                levelsSnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data && data.level) {
                        levelsSet.add(parseInt(data.level));
                    }
                });
                const maxLevel = Math.max(...levelsSet);
                setTotalLevels(maxLevel);
            } catch (error) {
                console.error("Error fetching total levels:", error);
            } finally {
                setIsLoadingLevels(false);
            }
        };
        fetchTotalLevels();
    }, []);

    const fetchKanjiDataForLevel = async () => {
        const levelStr = level.toString();
        const kanjiQuery = query(
            collection(db, "allKanji"),
            where("level", "==", levelStr),
            orderBy("lexicographicKanjiID")
        );
        const querySnapshot = await getDocs(kanjiQuery);
        return querySnapshot.docs.map(doc => doc.data());
    };

    const { data: kanjiList, isLoading: loadingKanji, error } = useQuery({
        queryKey: ['kanjiData', level],
        queryFn: fetchKanjiDataForLevel,
        enabled: !isLoadingLevels,
        onSuccess: (data) => console.log(`Kanji data for level ${level} loaded successfully`, data),
        onError: (error) => console.error("Error fetching Kanji data", error)
    });

    if (userLoading || isLoadingLevels || loadingKanji) {
        return (
            <div className="flex justify-center items-center h-screen bg-white">
                <ClipLoader color={"#014156"} size={50} />
            </div>
        );
    }

    if (error) {
        navigate('/error', { state: { errorMessage: `Error fetching Kanji: ${error.message}`, location: 'Level Page' } });
        return null;
    }

    // Calculate previous and next levels
    const currentLevel = parseInt(level);
    const prevLevel = currentLevel - 1 > 0 ? currentLevel - 1 : null;
    const nextLevel = currentLevel + 1 <= totalLevels ? currentLevel + 1 : null;

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className='container mx-auto space-y-4 p-6'>
                <div className='flex flex-col items-center bg-lightBlueBackground text-lightBlueText p-4 rounded-lg mt-10'>
                    <div className='text-2xl font-bold'>Level {level} Kanji</div>
                    <div className='text-lg'>Joyo Kanji {(currentLevel - 1) * 20 + 1}-{currentLevel * 20}</div>
                </div>

                {kanjiList && kanjiList.length > 0 ? (
                    <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-6'>
                        {kanjiList.map((kanji) => (
                            <Link to={`/kanji/${kanji.kanji}`} key={kanji.kanji} className='flex flex-col items-center p-4 bg-white border rounded-lg shadow hover:bg-blue-100 transition duration-300'>
                                <div className='text-4xl font-bold mb-2'>{kanji.kanji}</div>
                                <div className='text-gray-600'>{kanji.primaryMeaning}</div>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className='text-center p-8 bg-yellow-100 rounded-lg mt-6'>
                        <p className='text-xl font-semibold text-yellow-800'>
                            This level is under rapid development. Please join the discord for the latest updates and timelines.
                        </p>
                    </div>
                )}

                {/* Navigation Buttons */}
                <div className='flex justify-between mt-6'>
                    {prevLevel ? (
                        <Link
                            to={`/levels/${prevLevel}`}
                            className='px-4 py-2 rounded bg-blue-500 text-white'
                        >
                            Previous Level
                        </Link>
                    ) : (
                        <div />
                    )}
                    {nextLevel ? (
                        <Link
                            to={`/levels/${nextLevel}`}
                            className='px-4 py-2 rounded bg-blue-500 text-white'
                        >
                            Next Level
                        </Link>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        </div>
    );
}

export default LevelPage;
