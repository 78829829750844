import React, { useEffect } from 'react';
import refreshIcon from '../images/refresh-icon.png';
import oniKanjiMikanReviews from '../images/appHomeAssets/onikanji-mikan-reviews-graphic.png';
import oniKanjiMikanNewLessons from '../images/appHomeAssets/onikanji-mikan-new-lessons-graphic.png';


function DashboardSummary({ newLessons, reviews, upcomingReviews, onNewLessonClick, onReviewsClick, onRefreshClick }) {
  
  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-6 sm:mt-10">
      <div 
        className="w-full md:w-1/4 bg-lightOrangeBackground p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between items-center relative overflow-hidden cursor-pointer transition-all duration-300 hover:bg-darkOrangeColor group"
        onClick={onNewLessonClick}
      >
        <div className="absolute inset-0 w-full h-full bg-lightOrangeBackground group-hover:bg-darkOrangeColor transition-colors duration-300"></div>

        <p className="text-center text-2xl md:text-4xl font-bold text-lightOrangeText relative z-10 group-hover:text-white transition-colors duration-300">New Lessons</p>
        
        <p className="text-center text-5xl md:text-7xl font-bold text-lightOrangeText my-4 relative z-10 group-hover:text-white transition-colors duration-300">{newLessons}</p>
        <div className="relative z-10"><img src={oniKanjiMikanNewLessons} alt="" className="w-48 h-48 object-cover" /></div>
      </div>
      <div 
        className="w-full md:w-1/4 bg-lightBlueBackground p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between items-center relative overflow-hidden cursor-pointer transition-all duration-300 hover:bg-darkBlueColor group"
        onClick={onReviewsClick}
      >
        <div className="absolute inset-0 w-full h-full bg-lightBlueBackground group-hover:bg-darkBlueColor transition-colors duration-300"></div>

        <p className="text-center text-2xl md:text-4xl font-bold text-lightBlueText relative z-10 group-hover:text-white transition-colors duration-300">Reviews</p>
        <p className="text-center text-5xl md:text-7xl font-bold text-lightBlueText my-4 relative z-10 group-hover:text-white transition-colors duration-300">{reviews}</p>
        <div className="relative z-10"><img src={oniKanjiMikanReviews} alt="" className="w-48 h-48 object-cover" /></div>
      </div>
      <div className="w-full md:w-1/2 bg-white p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between">
        <div className="flex items-center mb-6 justify-between">
          <p className="text-left text-xl sm:text-2xl md:text-4xl font-bold">Upcoming Reviews</p>
          <img 
            src={refreshIcon} 
            alt="Refresh" 
            className="w-4 h-4 sm:w-6 sm:h-6 ml-2 cursor-pointer transition-transform duration-300" 
            onClick={() => {
              const icon = document.getElementById('refresh-icon');
              icon.classList.add('animate-spin');
              onRefreshClick();
              setTimeout(() => {
                icon.classList.remove('animate-spin');
              }, 5000);
            }} 
            id="refresh-icon"
          />
        </div>
        <div className="flex flex-col items-start w-full flex-grow justify-between">
          {['thirtyMinutes', 'fourHours', 'oneDay', 'threeDays', 'sevenDays', 'thirtyDays'].map((period) => {
            const value = upcomingReviews[period] || 0;
            const textColor = value === 0 ? 'text-lightOrangeText' : 'text-lightBlueText';
            const bgColor = value === 0 ? 'bg-lightOrangeBackground' : 'bg-lightBlueBackground';
            return (
              <p key={period} className="text-xs sm:text-sm md:text-md flex justify-between w-full mb-2 sm:mb-0">
                <span className="font-semibold">{getPeriodLabel(period)}</span>
                <span className={`ml-auto font-bold ${textColor} ${bgColor} rounded-md px-1 sm:px-2 py-1`}>+{value}</span>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function getPeriodLabel(period) {
  switch (period) {
    case 'thirtyMinutes': return 'Next 30 Minutes';
    case 'fourHours': return 'Next 4 Hours';
    case 'oneDay': return '1 Day';
    case 'threeDays': return '3 Days';
    case 'sevenDays': return '7 Days';
    case 'thirtyDays': return '30 Days';
    default: return period;
  }
}

export default DashboardSummary;