import stringSimilarity from 'string-similarity';

// Function to normalize strings based on question type
function normalizeString(str, inputType) {
    let normalizedStr = str.toLowerCase().trim();

    switch (inputType) {
        case 'english':
            normalizedStr = replaceDigitsWithNumberWords(normalizedStr);
            normalizedStr = stemString(normalizedStr);
            // Remove extra whitespace
            normalizedStr = normalizedStr.replace(/\s+/g, ' ').trim();
            // Remove punctuation
            normalizedStr = normalizedStr.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
            break;
        case 'hiragana':
            // For hiragana, we only trim and don't modify the string otherwise
            normalizedStr = normalizedStr.trim();
            break;
        // Add cases for other question types as needed
        default:
            break;
    }

    return normalizedStr;
}

// Function to replace digits with number words
function replaceDigitsWithNumberWords(str) {
    const numberWordsMap = {
        '0': 'zero', '1': 'one', '2': 'two', '3': 'three', '4': 'four', '5': 'five', '6': 'six', '7': 'seven', '8': 'eight', '9': 'nine',
        '10': 'ten', '11': 'eleven', '12': 'twelve', '13': 'thirteen', '14': 'fourteen', '15': 'fifteen', '16': 'sixteen', '17': 'seventeen', '18': 'eighteen', '19': 'nineteen',
        '20': 'twenty', '30': 'thirty', '40': 'forty', '50': 'fifty', '60': 'sixty', '70': 'seventy', '80': 'eighty', '90': 'ninety',
        '100': 'hundred', '1000': 'thousand', '1000000': 'million'
    };

    // Replace single digits and numbers up to 20
    str = str.replace(/\b(\d{1,2})\b/g, (match, p1) => numberWordsMap[p1] || match);

    // Handle larger numbers
    str = str.replace(/\b(\d+)\b/g, (match, p1) => {
        if (numberWordsMap[p1]) return numberWordsMap[p1];
        
        const num = parseInt(p1);
        if (num > 20 && num < 100) {
            const tens = Math.floor(num / 10) * 10;
            const ones = num % 10;
            return `${numberWordsMap[tens]}${ones > 0 ? '-' + numberWordsMap[ones] : ''}`;
        }
        return match;
    });

    return str;
}

// Function for simple stemming (e.g., removing plural 's')
function stemString(str) {
    // Remove plural 's' at the end of words
    return str.replace(/\b(\w+)s\b/g, '$1');
}

// Main function to check the answer
export function checkAnswer(inputType, correctAnswer, userInput) {
    // Check if user input is blank
    if (!userInput || userInput.trim() === '') {
        return false;
    }

    const normalizedUserInput = normalizeString(userInput, inputType);

    // Ensure correctAnswer is an array
    const correctAnswers = Array.isArray(correctAnswer) ? correctAnswer : [correctAnswer];

    // Expand correct answers by splitting on commas
    const expandedCorrectAnswers = correctAnswers.flatMap(ans => 
        ans.split(',').map(part => normalizeString(part.trim(), inputType))
    );

    if (inputType === 'hiragana') {
        // For hiragana, use direct comparison
        return expandedCorrectAnswers.includes(normalizedUserInput);
    } else {
        // For other types, use similarity check
        const similarityThreshold = 0.70;

        return expandedCorrectAnswers.some(ans => {
            const similarity = stringSimilarity.compareTwoStrings(ans, normalizedUserInput);
            return similarity >= similarityThreshold;
        });
    }
}