import React, { useMemo } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function LevelStatsChart({ currentLevel, activeStats, chartData, handleStatsSectionClick }) {
    const getDataKey = () => {
        switch (activeStats) {
            case 'accuracy': return 'accuracy';
            case 'speed': return 'speed';
            case 'reviewTime': return 'reviewTime';
            case 'currentStreak': return 'currentStreak';
            default: return '';
        }
    };

    const getYAxisDomain = () => {
        switch (activeStats) {
            case 'accuracy': return [0, 100];
            case 'speed': return [0, 60];
            case 'reviewTime': return [0, 'auto'];
            case 'currentStreak': return [0, 'auto'];
            default: return [0, 'auto'];
        }
    };

    const getYAxisLabel = () => {
        switch (activeStats) {
            case 'accuracy': return 'Accuracy (%)';
            case 'speed': return 'Speed (Seconds)';
            case 'reviewTime': return 'Review Time (Minutes)';
            case 'currentStreak': return 'Current Streak';
            default: return '';
        }
    };

    // New function to transform the chart data
    const transformedChartData = useMemo(() => {
        return chartData.map(item => ({
            ...item,
            accuracy: item.accuracy * 100
        }));
    }, [chartData]);

    return (
        <div className="w-full bg-white rounded-lg shadow-blueBoxShadow p-4 sm:p-6 mt-6 sm:mt-10 space-y-4 sm:space-y-6">
            <div className="text-left mb-4 sm:mb-6">
                <p className="text-2xl sm:text-3xl font-bold">Your {currentLevel} Level Stats</p>
            </div>

            <div className="flex flex-wrap justify-start w-full">
                <div className="flex flex-wrap gap-2">
                    {['accuracy', 'speed', 'reviewTime', 'currentStreak'].map((stat) => (
                        <button
                            key={stat}
                            className={`px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium border border-darkOrangeColor ${activeStats === stat ? 'bg-darkOrangeColor text-white' : 'bg-white text-darkOrangeColor'}`}
                            onClick={() => handleStatsSectionClick(stat)}
                        >
                            {stat === 'reviewTime' ? 'Total Review Time' : stat.charAt(0).toUpperCase() + stat.slice(1)}
                        </button>
                    ))}
                </div>
            </div>
            <div className="w-full h-[300px] md:h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={transformedChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tickMargin={5} fontSize={14} angle={-45} textAnchor="end" height={60} />
                        <YAxis 
                            label={{ 
                                value: getYAxisLabel(), 
                                angle: -90, 
                                position: 'insideLeft',
                                style: { fontSize: '14px', textAnchor: 'middle' }
                            }} 
                            fontSize={14}
                            domain={getYAxisDomain()}
                            tickFormatter={(value) => activeStats === 'accuracy' ? `${value}%` : value}
                        />
                        <Tooltip 
                            formatter={(value, name) => [
                                activeStats === 'accuracy' ? `${value.toFixed(2)}%` : value,
                                name
                            ]}
                        />
                        <Legend wrapperStyle={{ fontSize: '14px' }} />
                        <Bar dataKey={getDataKey()} fill="#009BCE" name={getYAxisLabel()} shape={<Rectangle radius={[5, 5, 0, 0]} />} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default LevelStatsChart;
